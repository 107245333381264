import React, { useEffect, useState } from "react";
import Style from "./style.module.css";

interface CarouselProps {
  itemList: JSX.Element[];
}

export default function Carousel({ itemList }: CarouselProps) {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextClient = () => {
    if (activeIndex === itemList.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  useEffect(() => {
    const updateClient = () => {
      setTimeout(nextClient, 4000);
    };

    updateClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <section className={Style.carousel__container}>
      <div className={Style.item__container}>
        {itemList.map((item, index) => {
          let position =
            activeIndex === index
              ? "0"
              : index > activeIndex
              ? "500px"
              : "-500px";

          return (
            <div
              className={Style.carousel__item}
              key={index + "carousel_item"}
              style={{left: position}}
            >
              {item}
            </div>
          );
        })}
      </div>
    </section>
  );
}
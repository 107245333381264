import React from "react";
import style from "./style.module.css";
import EmailSender from "../../services";
import { useFormik } from "formik";
import {
  BsFillExclamationCircleFill,
  BsFillCheckCircleFill,
} from "react-icons/bs";
import FormSchema from "../../schemas";
import { EmailJSResponseStatus } from "@emailjs/browser";

export default function ContactForm() {
  const formik = useFormik({
    ...FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async ({
    name,
    email,
    phone,
    message,
  }: {
    name: string;
    email: string;
    phone: string;
    message: string;
  }) => {
    const emailSender = new EmailSender(name, email, phone, message);

    const res = await emailSender.send() as EmailJSResponseStatus;

    formik.resetForm()

    return res;
  };

  const getClass = {
    nameClass: formik.touched.name && formik.errors.name ? 'invalid__input' : (formik.values.name &&
      !formik.errors.name) ? 'valid__input' : '',
    emailClass: formik.touched.email && formik.errors.email ? 'invalid__input' : (formik.values.email &&
      !formik.errors.email) ? 'valid__input' : '',
    phoneClass: formik.touched.phone && formik.errors.phone ? 'invalid__input' : (formik.values.phone &&
      !formik.errors.phone) ? 'valid__input' : '',
    msgClass: formik.touched.message && formik.errors.message ? 'invalid__textbox' : (formik.values.message &&
      !formik.errors.message) ? 'valid__textbox' : '',
  }

  return (
    <form className={style.form__container} data-testid="contact__form" onSubmit={formik.handleSubmit}>
      <label htmlFor="name" className={style.input__container}>
        Nome <strong>*</strong>
        <br />
        <input
          placeholder="Digite seu nome"
          type="text"
          name="name"
          id="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`${style.contact__input} ${style[getClass.nameClass]}`}
          data-testid="input"
        />
        {formik.touched.name && formik.errors.name ? (
          <>
            <div className={style.invalid__mark}>
              <BsFillExclamationCircleFill />
            </div>
            <div className={style.err__msg} >{formik.errors.name}</div>
          </>
        ) : (
          formik.values.name &&
          !formik.errors.name && (
            <div className={style.valid__mark}>
              <BsFillCheckCircleFill />
            </div>
          )
        )}
      </label>
      <label htmlFor="email" className={style.input__container}>
        Email <strong>*</strong>
        <br />
        <input
          placeholder="Digite seu email"
          type="email"
          name="email"
          id="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`${style.contact__input} ${style[getClass.emailClass]}`}
          data-testid="input"
        />
        {(formik.touched.email && formik.errors.email) ||
        (formik.values.email && formik.errors.email) ? (
          <>
            <div className={style.invalid__mark}>
              <BsFillExclamationCircleFill />
            </div>
            <div className={style.err__msg} >{formik.errors.email}</div>
          </>
        ) : (
          formik.values.email &&
          !formik.errors.email && (
            <div className={style.valid__mark}>
              <BsFillCheckCircleFill />
            </div>
          )
        )}
      </label>
      <label htmlFor="phone" className={style.input__container}>
        Telefone <strong>*</strong>
        <br />
        <input
          placeholder="Digite seu número de telefone com ddd"
          type="text"
          name="phone"
          id="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`${style.contact__input} ${style[getClass.phoneClass]}`}
          data-testid="input"
        />
        {(formik.touched.phone && formik.errors.phone) ||
        (formik.values.phone && formik.errors.phone) ? (
          <>
            <div className={style.invalid__mark}>
              <BsFillExclamationCircleFill />
            </div>
            <div className={style.err__msg}>{formik.errors.phone}</div>
          </>
        ) : (
          formik.values.phone &&
          !formik.errors.phone && (
            <div className={style.valid__mark}>
              <BsFillCheckCircleFill />
            </div>
          )
        )}
      </label>
      <label htmlFor="message" className={style.input__container}>
        Mensagem <strong>*</strong>
        <br />
        <textarea
          placeholder="Digite sua mensagem"
          name="message"
          id="message"
          cols={30}
          rows={10}
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`${style.contact__textBox} ${style[getClass.msgClass]}`}
          data-testid="input"
        ></textarea>
        {formik.touched.message && formik.errors.message ? (
          <>
            <div className={style.invalid__mark}>
              <BsFillExclamationCircleFill />
            </div>
            <div className={style.err__msg}  >{formik.errors.message}</div>
          </>
        ) : (
          formik.values.message &&
          !formik.errors.message && (
            <div className={style.valid__mark}>
              <BsFillCheckCircleFill />
            </div>
          )
        )}
      </label>
      <button type="submit" data-testid="submit__button" disabled={!formik.isValid}>ENVIAR</button>
    </form>
  );
}

import React from "react";
import { Header } from "../../components";
import { useParams } from "react-router-dom";

export default function Service() {
  let { serviceId } = useParams();
  const TAGS_LIST = [
    "home",
    "topografia",
    "regularização",
    "licença",
    "corretagem",
    "contato",
  ];

  return (
    <main>
      <Header pageId={Number(serviceId)} />
      {serviceId && TAGS_LIST[Number(serviceId)]}
    </main>
  );
}

import emailJs from "@emailjs/browser";

export default class EmailSender {
  private emailService: typeof emailJs;

  constructor(
    private name: string,
    private email: string,
    private phone: string,
    private message: string
  ) {
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.message = message;
    this.emailService = emailJs;
  }

  send = async () => {
    const { name, email, phone, message, emailService } = this;

    const params = {
      name,
      email,
      phone,
      message,
    };

    try {
      const res = await emailService.send(
        "service_etaysbs",
        "template_hgvzrxo",
        params,
        "UyX1xm5V4auXV57Pu"
      );
      return res;
    } catch (error) {
      return error;
    }
  };
}

import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import logo from "../../images/logo_branco_sem_fundo.png";

function Footer() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer data-testid="footer">
      <div className={style.info}>
        <img src={logo} alt="" />
        <section className={style.info__container}>
          <div className={style.address}>
            <h2>Nosso Endereço:</h2>
            <a
              href="https://goo.gl/maps/JnwNSdV2uwjETTDJ7"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                Rua Santana do Iapó, nº 31, <br />
                Ed. Livorno, 2° andar, sala 203
                <br />
                Muquiçaba, Guarapari - ES, Brasil.
                <br />
                CEP: 29215-020
              </span>
            </a>
          </div>
          <div>
            <h2>Contatos:</h2>
            <span>
              (27) 99526-9598{" "}
              <a
                href="https://contate.me/thiago_trs"
                target="_blank"
                rel="noreferrer"
                className={style.wpp}
              >
                (Whatsapp)
              </a>{" "}
              <br />
              (28) 99914-0102{" "}
              <a
                href="https://contate.me/vitor_trs"
                target="_blank"
                rel="noreferrer"
                className={style.wpp}
              >
                (Whatsapp)
              </a>{" "}
              <br />
              <a
                href="mailto:contato.trsservicos@gmail.com"
                className={style.email}
              >
                <strong>contato@trsengenharia.com</strong>
              </a>
            </span>
          </div>
        </section>
      </div>
      <section className={style.copyright}>
        {width > 600 ? (
          <span>
            Copyright 2024 | Todos os direitos reservados | Desenvolvido por{" "}
            <a
              href="https://contate.me/andre_ars"
              target="_blank"
              rel="noreferrer"
            >
              André Rodrigues
            </a>
          </span>
        ) : (
          <span>
            Copyright 2023 <br /> Todos os direitos reservados <br />{" "}
            Desenvolvido por{" "}
            <a
              href="https://contate.me/andre_ars"
              target="_blank"
              rel="noreferrer"
            >
              André Rodrigues
            </a>
          </span>
        )}
      </section>
    </footer>
  );
}

export default Footer;

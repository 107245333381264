import React from "react";
import Logo from "../../images/logo_branco_sem_fundo.png";
import style from "./style.module.css";
// import { Link } from "react-router-dom";

export interface HeaderProps {
  pageId: number;
}

export default function Header({ pageId }: HeaderProps) {
  // const [showMenu, setShowMenu] = useState(false);
  // const [width, setWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWidth(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // const TAGS_NAME = [
  //   "home",
  //   "topografia",
  //   "regularização",
  //   "licença",
  //   "corretagem",
  // ];

  // const navClassName =
  //   width <= 600 && showMenu ? style.nav__mobile : style.hidden__nav;

  // function renderTags() {
  //   return TAGS_NAME.map((tag: String, i) => {
  //     const className = i === pageId ? style.current__page : "";
  //     const testId = i === pageId ? "current__page" : `service__tag${i + 1}`;

  //     return (
  //       <Link
  //         onClick={() => toggleMenu()}
  //         to={i === 0 ? "/" : `/service/${i}`}
  //         key={TAGS_NAME[i]}
  //         className={className}
  //         data-testid={testId}
  //       >
  //         <span>{tag.toUpperCase()}</span>
  //       </Link>
  //     );
  //   });
  // }

  // function toggleMenu() {
  //   setShowMenu(!showMenu);
  // }

  return (
    <>
      <header
        className={style.header__container}
        data-testid="header__container"
      >
        {/* <nav className={style.nav__container} data-testid="nav__container">
          {renderTags()}
        </nav>
        <nav className={navClassName} data-testid="nav__mobile">
          {renderTags()}
        </nav>
        <section
          className={`${style.hamburguer__container} ${
            showMenu && style.show__x
          }`}
          onClick={() => toggleMenu()}
        >
          <div className={style.hamburguer__stick}></div>
        </section> */}
        <img src={Logo} alt="" />
      </header>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Carousel } from "../../components";
import style from "./style.module.css";
import logo from "../../images/logo_branco_sem_fundo.png";
import ServiceCards from "../../components/ServiceCards";
import apal from "../../images/Apal_cortada.png";
import donati from "../../images/Donati_cortada.png";
import med from "../../images/medsenior.png";
import oc from "../../images/oc.png";
import maely from "../../images/logoMaely.svg";
import grand from "../../images/logoGrand.webp";
import pnv from "../../images/logoPNV.png";
import ContactForm from "../../components/ContactForm";
import Footer from "../../components/Footer";
import { FaWhatsapp, FaArrowUp } from "react-icons/fa";

export default function Home() {
  const [goUp, setGoUp] = useState(false);

  useEffect(() => {
    const handleScroll = (e: Event) => {
      setGoUp(window.scrollY >= 600);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const SERVICES = [
    {
      tag: "topografia",
      categories: [
        "Levantamento",
        "Planta",
        "Voo de Drone",
        "Demarcação de Lotes",
        "Hortofotogrametria",
        "Laudo Fotográfico",
      ],
    },
    {
      tag: "regularização",
      categories: [
        "CAR - CCIR - ITR",
        "Certificação Rural",
        "Descaracterização Rural",
        "Desmembramento",
        "Despachante",
        "Edificação",
      ],
    },
    {
      tag: "licença",
      categories: [
        "Barragem - IDAF",
        "Corte e Aterro - SEMAG",
        "Exploração Florestal - IDAF",
        "Laudo Florestal -IDAF",
        "Licença Estrutural - SEMAP",
        "Multa Ambiental",
      ],
    },
    {
      tag: "corretagem",
      categories: [],
    },
    {
      tag: "engenharia",
      categories: ["Projeto de Terraplanagem"],
    },
  ];
  const CLIENTS = [med, apal, maely, donati, oc, grand, pnv];

  const renderCards = () => {
    return SERVICES.map(({ tag, categories }, i) => (
      <ServiceCards key={i} imgId={i} title={tag} categories={categories} />
    ));
  };

  const handleGoUp = (e: React.MouseEvent) => {
    window.scrollTo(0, 0);
  };

  const renderImages = () => {
    return CLIENTS.map((img, i) => <img src={img} alt="" key={i} />);
  };

  return (
    <main className={style.home__page}>
      <section className={style.drone__image} data-testid="drone__image">
        <div className={style.presentation__text}>
          <span>Com a TRS, o Cliente não trabalha</span>
        </div>
        <img src={logo} alt="" />
      </section>
      <section className={style.services} data-testid="services">
        <div className={style.section__title}>
          <h1>SERVIÇOS</h1>
          <hr />
        </div>
        <div className={style.cards__container} data-testid="cards__container">
          {renderCards()}
        </div>
      </section>
      <section
        className={style.transition__section}
        data-testid="transition__section"
      >
        <div className={style.trademark__container}>
          <div className={style.trademark}>
            <span>
              Com a TRS o cliente tem tudo que precisa para seu imovel rural
            </span>
            <a
              href="https://contate.me/thiago_trs"
              target="_blank"
              rel="noreferrer"
              data-testid="contact__button"
            >
              <button type="button">
                Fale Conosco <FaWhatsapp />{" "}
              </button>
            </a>
          </div>
        </div>
      </section>
      <section
        className={style.clients__section}
        data-testid="clients__section"
      >
        <div className={style.clients__container}>
          <div className={style.section__title}>
            <h1>CLIENTES</h1>
            <hr />
          </div>
          <div className={style.clients__logo}>{renderImages()}</div>
          <div className={style.carousel}>
            <Carousel itemList={renderImages()} />
          </div>
        </div>
      </section>
      <section
        className={style.contact__section}
        data-testid="contact__section"
      >
        <div className={style.contact__container}>
          <div className={style.section__title}>
            <h1>CONTATO</h1>
            <hr />
          </div>
          <p>Preencha este formulário e solicite um orçamento</p>
          <ContactForm />
        </div>
      </section>
      <div
        className={`${style.go__up} ${goUp ? style.enabled : style.disabled}`}
        data-testid="go__up"
      >
        <button type="button" onClick={(e) => handleGoUp(e)}>
          <FaArrowUp />
        </button>
      </div>
      <Footer />
    </main>
  );
}

import * as yup from "yup";

const FormSchema = {
  initialValues: {
    name: "",
    email: "",
    phone: "",
    message: "",
  },
  validationSchema: yup.object({
    name: yup.string().required("Campo obrigatório"),
    email: yup
      .string()
      // .matches(/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$/i, "E-mail inválido")
      .email("E-mail inválido")
      .required("Campo obrigatório"),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, "Permitido apenas números")
      .min(11, "Número inválido")
      .max(11, "Número inválido")
      .required("Campo obrigatório"),
    message: yup
      .string()
      .min(25, "Mensagem muito pequena")
      .required("Campo obrigatório"),
  }),
};

export default FormSchema;

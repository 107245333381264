import React from "react";
import style from "./style.module.css";
import topography from "../../images/topography-202278_1280.jpg";
import regularization from "../../images/regularização.jpeg";
import licence from "../../images/licença.jpeg";
import realState from "../../images/realState_compact.jpg";
import engineering from "../../images/eng.png";
// import { useNavigate } from "react-router-dom";
interface CardProps {
  imgId: number;
  title: string;
  categories:  string[];
}

export default function ServiceCards({ imgId, title, categories }: CardProps) {
  // const navigate = useNavigate();

  // const handleClick = () => {
  //   navigate(`/service/${imgId + 1}`);
  // };

  const IMGS = [topography, regularization, licence, realState, engineering];

  return (
    <div
      className={style.card__container}
      key={title}
      data-testid="service__card"
    >
      <div
        className={style.list__container}
        // onClick={() => handleClick()}
        data-testid={`service__list${imgId}`}
      >
        <ul>
          {
            categories.map((category, index) =>
              <li key={index}>{category}</li>
            )
          }
        </ul>
      </div>
      <div>
        <img src={IMGS[imgId]} alt="" />
        <div className={style.tag}>
          <span>{title.toUpperCase()}</span>
        </div>
      </div>
    </div>
  );
}
